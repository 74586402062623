import { ReactElement, useMemo, useState } from 'react';
import { isEmpty, without } from 'lodash';
import { tx } from '@/localization/i18n';
import {
	Button,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	Select,
	TextField,
	styled,
	Checkbox,
	Box,
	Radio,
} from '@mui/material';
import theme from '@/styles/theme';

const DialogTextField = styled(TextField)({
	marginTop: '1rem',
	width: '100%',
	'& input': {
		padding: '0.5rem 1rem',
	},
});

const DialogPopup = styled(MuiDialog)({});

const RadioGroup = styled(Box)({
	padding: '0.5rem 0',
	'& .MuiRadio-root': {
		padding: '0.5rem',
	},
});

export type DialogType = 'OK' | 'OKCancel' | 'YesNo' | 'YesNoCancel';

export const DUMMY_SELECT_VALUE = '___DUMMY___';

export enum DialogResponse {
	OK = 'OK',
	CANCEL = 'CANCEL',
	YES = 'YES',
	NO = 'NO',
}

export const DialogTypeButtons: Record<DialogType, DialogResponse[]> = {
	OK: [DialogResponse.OK],
	OKCancel: [DialogResponse.OK, DialogResponse.CANCEL],
	YesNo: [DialogResponse.YES, DialogResponse.NO],
	YesNoCancel: [DialogResponse.YES, DialogResponse.NO, DialogResponse.CANCEL],
};

type TextPart = string | ReactElement;

export interface DialogProps {
	title?: TextPart | TextPart[];
	text?: TextPart | TextPart[];
	type?: DialogType;
	selectOptions?: Record<string, any>;
	multiSelectOptions?: Record<string, any>;
	radioOptions?: Record<string, any>;
	inputTextPropmpt?: string;
	textAsCode?: boolean;
	menuItems?: [{ text: string; onClick: () => void }];
	children?: ReactElement;
	buttons?: string[];
	onClose?: (value?: any) => void;
	onApprove?: (value?: any) => void;
	onCancel?: () => void;
}

export default function Dialog({
	title,
	text,
	textAsCode,
	type,
	selectOptions,
	multiSelectOptions,
	radioOptions,
	inputTextPropmpt,
	menuItems,
	children,
	buttons,
	onClose,
	onApprove,
	onCancel,
}: DialogProps) {
	const [selectedValue, setSelectedValue] = useState<any>(DUMMY_SELECT_VALUE);
	const [inputText, setInputText] = useState<any>('');
	const [open, setOpen] = useState(true);
	const [selectedOptions, setSelectedOptions] = useState([] as any[]);
	const [radioValue, setRadioValue] = useState(Object.values(radioOptions || {})[0]);

	function handleClose(type: DialogResponse | string) {
		let closeValue;
		if (type === 'CANCEL') {
			onCancel && onCancel();
		} else {
			onApprove && onApprove(inputText || selectedValue);
			const realSelectedValue = selectedValue === DUMMY_SELECT_VALUE ? null : selectedValue;
			closeValue =
				inputText ||
				realSelectedValue ||
				(multiSelectOptions && selectedOptions) ||
				(radioOptions && radioValue) ||
				type;
		}
		if (onClose) onClose(closeValue);
	}

	function textAsRows() {
		if (Array.isArray(text)) return text;
		if (typeof text !== 'string') return [text];
		return text.split('\n').map((row) => (
			<>
				{row}
				<br />
			</>
		));
	}

	const content = useMemo(() => {
		if (textAsCode) {
			return (
				<textarea
					style={{
						minWidth: '80vw',
						minHeight: '70vh',
						direction: 'ltr',
						fontFamily: "'Roboto Mono', monospace",
						fontSize: '1rem',
					}}
				>
					{text}
				</textarea>
			);
		} else {
			return textAsRows();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [text, textAsCode]);

	function handleInputTextKeyUp(event: any) {
		if (event.key === 'Enter') handleClose('OK');
	}

	return (
		<DialogPopup
			open={open}
			onClose={() => onClose && onClose('CANCEL')}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="xl"
		>
			{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
			<DialogContent>
				{text && <DialogContentText id="alert-dialog-description">{content}</DialogContentText>}
				{inputTextPropmpt !== undefined && (
					<DialogTextField
						placeholder={inputTextPropmpt}
						id="alert-dialog-input-text"
						value={inputText}
						onChange={(event) => setInputText(event.target.value)}
						onKeyDown={handleInputTextKeyUp}
						autoComplete="off"
					/>
				)}
				{selectOptions && (
					<Select
						id="dialog-single-select"
						value={selectedValue}
					>
						{Object.entries(selectOptions).map(([label, value]) => (
							<MenuItem
								key={label}
								value={value}
								onClick={() => setSelectedValue(value)}
							>
								{value ? label : <em>{label}</em>}
							</MenuItem>
						))}
					</Select>
				)}
				{(menuItems || []).map(({ text, onClick }) => (
					<MenuItem
						key={text}
						onClick={() => {
							setOpen(false);
							onClick();
						}}
					>
						{text}
					</MenuItem>
				))}
				{Object.entries(multiSelectOptions || {}).map(([label, value]) => (
					<Box
						sx={{ display: 'flex', alignItems: 'center' }}
						key={label}
					>
						<Checkbox
							value={selectedOptions.includes(value)}
							onClick={() =>
								setSelectedOptions(
									selectedOptions.includes(value) ? without(selectedOptions, value) : [...selectedOptions, value]
								)
							}
						/>
						<span>{label}</span>
					</Box>
				))}
				{!isEmpty(radioOptions) && (
					<RadioGroup>
						{Object.entries(radioOptions).map(([label, value]) => (
							<Box key={label}>
								<Radio
									checked={radioValue === value}
									onClick={() => setRadioValue(value)}
								/>
								{label}
							</Box>
						))}
					</RadioGroup>
				)}
				{children}
			</DialogContent>
			<DialogActions>
				{(buttons || DialogTypeButtons[type || 'OK']).map((button) => (
					<Button
						key={button}
						onClick={() => handleClose(button)}
					>
						{tx('DIALOG', button)}
					</Button>
				))}
			</DialogActions>
		</DialogPopup>
	);
}
