import React, { useRef } from 'react';
import { createContext, ReactElement, useContext, useState, useEffect } from 'react';
import * as Api from '@/api/server/user';
import { getTimeToTokenExpiration } from '@/api/server/authUtil';
import { useRouter } from 'next/router';
import { IUser, isUserAdmin, isUserCustomer, isUserManager, isUserTranscriptionistOrEditor } from '@common/types/User';
import LoadingScreen from '@/components/layout/LoadingScreen';
import { tx } from '@/localization/i18n';
import { CHANGE_PASSWORD_PAGE_PATH, LOGIN_PAGE_PATH } from '../paths';
import { useVisualLayoutContext } from './VisualLayoutProvider';
import { SECOND } from '@common/misc';

let _currentUser: IUser | undefined;

let checkLoggedInInterval: NodeJS.Timer | undefined;

export function currentUser() {
	return _currentUser;
}

export function isExperimental() {
	return true; // TODO
	//return typeof window !== 'undefined' && Boolean(window.localStorage.getItem('experimental'));
}

export type RunApiFunction = (run: (...params: any[]) => any, ...params: any[]) => any;

export interface AuthContextType {
	isInitialized: boolean;
	user?: IUser;
	isLoggingIn?: boolean;
	isLoggedIn?: boolean;
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
	refreshCurrentUser: () => void;
	error: string;
	canAccessPage: (path: string) => boolean;
	runApi: RunApiFunction;
}

export const AuthContext = createContext({} as AuthContextType);

const REDIRECT_URL = '__REDIRECT_URL__';
const saveUrlBeforeLogout = () => localStorage.setItem(REDIRECT_URL, location.href); //.pathname + location.search);
const getUrlBeforeLogout = () => localStorage.getItem(REDIRECT_URL);
const resetUrlBeforeLogout = () => localStorage.removeItem(REDIRECT_URL);

export function AuthProvider({ children }: { children: ReactElement }) {
	const [error, setError] = useState('');
	const isLoggingIn = useRef(false);
	const isInitializing = useRef(false);
	const isInitialized = useRef(false);
	const [user, setUser] = useState<IUser | undefined>(undefined);
	const [refresher, setRefresher] = useState(0);
	const { cue, clearCues } = useVisualLayoutContext();

	function refresh() {
		setRefresher(refresher + 1);
	}

	const isFetchingCurrentUser = useRef(false);

	const canAccessPage = (path: string) => {
		if (!user) return false;
		const isAdmin = isUserAdmin(user as IUser);
		if (isAdmin) return true;
		if (path.startsWith('/admin/') && !isAdmin) return false;
		if (path.startsWith('/manager/') && !isUserManager(user as IUser)) return false;
		if (path.startsWith('/transcriptionist/') && !isUserTranscriptionistOrEditor(user as IUser)) return false;
		if (path.startsWith('/customer/') && !isUserCustomer(user as IUser)) return false;
		return true;
	};

	const runApi = async (run: (...args: any[]) => any, ...params: any[]) => {
		try {
			if (!run) debugger;
			return await run(...params);
		} catch (err: any) {
			console.error({ runApiError: { run, params, err } });
			if (err.networkError?.statusCode === 401) {
				saveUrlBeforeLogout();
				logout!();
			} else {
				throw err;
			}
		}
	};

	async function getCurrentUser() {
		if (isFetchingCurrentUser.current) return;
		try {
			isFetchingCurrentUser.current = true;
			_currentUser = await Api.getCurrentUser();
			setUser(_currentUser);
		} finally {
			isFetchingCurrentUser.current = false;
		}
	}

	const login = async (email: string, password: string) => {
		setError('');
		isLoggingIn.current = true;
		refresh();
		try {
			await Api.login(email, password);
			await getCurrentUser();
			const url = getUrlBeforeLogout();
			resetUrlBeforeLogout();
			// TODO redirect to URL before login
			setupLoggedInChecks();
		} catch (err) {
			setError(tx('ERROR', (err as any).message || 'תקלה כללית') as string);
		}
		isLoggingIn.current = false;
		refresh();
	};

	const logout = () => {
		if (checkLoggedInInterval) {
			clearInterval(checkLoggedInInterval);
			checkLoggedInInterval = undefined;
		}
		Api.logout();
		// if logout is due to an error, it will only happen after this. erase it
		setTimeout(clearCues, 100);
		setUser(undefined);
	};

	const setupLoggedInChecks = () => {
		if (checkLoggedInInterval) return;
		checkLoggedInInterval = setInterval(() => {
			const timeToTokenExpiration = getTimeToTokenExpiration();
			if (timeToTokenExpiration <= 0) {
				saveUrlBeforeLogout();
				logout!();
				setTimeout(() => cue('info', 'נא להתחבר מחדש'), 200); // after cues are cleared
			} else if (timeToTokenExpiration <= 20 * SECOND) {
				cue('warning', 'בקרוב האתר יתנתק ותתבקשו להזין שוב משתמש וסיסמא', '', 'WARN_LOGIN_TIMEOUT');
			}
		}, 10000);
	};

	useEffect(() => {
		async function initialize() {
			if (isInitializing.current || isInitialized.current) return;
			isInitializing.current = true;

			try {
				await getCurrentUser();
				setupLoggedInChecks();
			} catch (err) {
				setUser(undefined);
			} finally {
				isInitializing.current = false;
				isInitialized.current = true;
				refresh();
			}
		}
		initialize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isInitialized: isInitialized.current,
				user,
				isLoggingIn: isLoggingIn.current,
				isLoggedIn: Boolean(user),
				error,
				login,
				logout,
				refreshCurrentUser: getCurrentUser,
				runApi,
				canAccessPage,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuthContext() {
	return useContext(AuthContext);
}

export function AuthenticationProtection({ children }: { children: ReactElement }) {
	const router = useRouter();
	const { isInitialized, isLoggedIn, user, canAccessPage } = useAuthContext();

	let redirectTo = '';

	const atLogin = router.asPath === LOGIN_PAGE_PATH;
	const atChangePassword = router.asPath.startsWith(CHANGE_PASSWORD_PAGE_PATH);
	const isPublic = atLogin || atChangePassword;
	const mightRedirectToLogin = !isPublic && !isLoggedIn && !isInitialized;

	if (!isPublic) {
		if (!user) {
			if (isInitialized && !isLoggedIn && !atLogin) {
				redirectTo = LOGIN_PAGE_PATH;
			}
		} else if (!canAccessPage(router.pathname)) {
			redirectTo = '/';
		}
	}

	if (atLogin && isLoggedIn) redirectTo = '/';

	useEffect(() => {
		if (redirectTo) router.replace(redirectTo);
	}, [router, redirectTo, isInitialized, user, isLoggedIn]);

	if (redirectTo) {
		return null;
	} else if (mightRedirectToLogin) {
		return <LoadingScreen />;
	} else {
		return children;
	}
}
