/* eslint-disable @next/next/no-img-element */
import { IUser } from '@common/types/User';
import { Person2Outlined } from '@mui/icons-material';

export default function Avatar({ user, size }: { user?: IUser; size: string }) {
	const profileImage = user?.profileImage;
	if (false && profileImage) {
		return (
			<img
				alt="תמונת פרופיל"
				src={profileImage!}
				style={{ width: size, height: size, borderRadius: `calc(${size} / 2)` }}
			/>
		);
	} else {
		return <Person2Outlined color="primary" />;
	}
}
