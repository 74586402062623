// import { StorageType } from "./types/Storage";

import { range } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsDuration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Env } from './env';
import path from 'path';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsDuration);
dayjs.extend(customParseFormat);

// dayjs.tz.setDefault('Europe/London');

export function sleep(ms: number): Promise<any> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const randomString = (length: number) => {
	const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
	const chars = Array.from({ length }).map(() => CHARS.charAt(Math.floor(Math.random() * CHARS.length)));
	return chars.join('');
};

export const randomTempFilePath = () => {
	return '/tmp/' + randomString(8);
};

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const thousnadsSeperator = (num: number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PHONE_REGEX = /^\+?(\d{1,10}[ .-]?){1,4}$/;

// Array

export const uniqueArray = <T>(arr: T[]) => Array.from(new Set(arr));

export const toggleArrayElement = (arr: any[], elem: any) => {
	const set = new Set(arr);
	if (set.has(elem)) {
		set.delete(elem);
	} else {
		set.add(elem);
	}
	return Array.from(set);
};

export const removeObjectNulls = (obj: Record<string, any>) => {
	Object.entries(obj).forEach(([key, value]) => {
		if (value === undefined) delete obj[key];
	});
	return obj;
};

export const intersectArray = <T = any>(arr1: T[], arr2: T[]): T[] => arr1.filter((x) => arr2.includes(x));

export const subtractArray = <T = any>(arr1: T[], arr2: T[]): T[] => arr1.filter((x) => !arr2.includes(x));

export const unionArray = <T = any>(arr1: T[], arr2: T[]): T[] => uniqueArray([...arr1, ...arr2]);

export const compareArraysNoOrder = <T = any>(arr1?: T[], arr2?: T[]): boolean =>
	!!arr1 && !!arr2 && arr1.length === arr2.length && arr1.length == new Set(arr1.concat(arr2)).size;

export const arrToDictByProp = <T extends { [key: string]: any }>(arr: T[] | undefined, prop: keyof T) => {
	const res: Record<string, T> = {};
	(arr || []).forEach((o) => {
		res[o[prop] as unknown as string] = o;
	});
	return res;
};

export function pickProps(obj: any, keys: string[]) {
	const res = {} as any;
	keys.forEach((key) => (res[key] = obj[key]));
	return res;
}

// Misc

export const promisify = (f: any): any => {
	return () => {
		return new Promise<any>((resolve: any) => {
			f((res: any) => resolve(res));
		});
	};
};

// DateTime

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const YEAR = 365.25 * DAY;

export const MINUTE_IN_SEC = 60;
export const HOUR_IN_SEC = 60 * MINUTE_IN_SEC;

export const timeFromNow = (date: Date): string => {
	if (!date || isNaN(date.valueOf())) return '?';

	let delta = new Date().valueOf() - date.valueOf();
	//	const sign = Math.sign(delta);
	delta = Math.abs(delta);

	let units;
	if (delta < MINUTE) units = { s: SECOND };
	else if (delta < HOUR && delta < 2 * MINUTE) units = { m: MINUTE, s: SECOND };
	else if (delta < HOUR) units = { m: MINUTE };
	else if (delta < DAY && delta < 2 * HOUR) units = { h: HOUR, m: MINUTE };
	else if (delta < DAY) units = { h: HOUR };
	else if (delta < WEEK && delta < 2 * DAY) units = { d: DAY, h: HOUR };
	else if (delta < WEEK) units = { d: DAY };
	else units = { week: WEEK };

	const unitsNames = Object.keys(units);
	const unitsValues = Object.values(units) as number[];
	const res: any[] = [delta / Number(unitsValues[0])];
	if (unitsValues.length > 1) {
		res[0] = Math.floor(res[0]);
		res[1] = Math.round((delta - res[0] * unitsValues[0]) / unitsValues[1]);
	} else {
		res[0] = Math.round(res[0]);
	}
	res[0] = res[0].toString() + unitsNames[0];
	if (unitsNames.length > 1) res[1] = res[1].toString() + unitsNames[1];

	return res.join(' ');
};

export function parseDateTime(value: any, format?: string) {
	return dayjs(value, format); // .tz('Europe/London');
}

export function dateWithoutTimeZone(date: Date) {
	return new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
}

export function dateOnly(year?: number | Date | any, month?: number, day?: number) {
	const date = year instanceof Date ? year : (year as any)?.toDate ? year!.toDate() : new Date();
	year = typeof year == 'number' ? year : date.getFullYear();
	month = month || date.getMonth() + 1;
	day = day || date.getDate();

	const dateString = `${year}-${month?.toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}`;
	const res = new Date(dateString);
	return res;
}

// Files and Streams

export const urlToFile = async (url: string, name: string): Promise<File> => {
	const response = await fetch(url);
	const data = await response.blob();
	const metadata = {
		type: 'image/jpeg',
	};

	return new File([data], name, metadata);
};

/*
import { Readable, Buffer } from 'stream';

export const streamToBuffer = (stream: Readable) => {
	if (!stream) {
		console.log('No Stream found!');
		return false;
	} else {
		return new Promise<Buffer>((res, rej) => {
			const chunks: any[] = [];
			stream.on('error', (err) => {
				console.error('Error reading stream', err);
				rej(err);
			});
			stream.on('data', (chunk) => chunks.push(chunk));
			stream.once('end', () => res(Buffer.concat(chunks)));
		});
	}
};
*/

// Web

export const changeFavicon = (env: string, isAdmin = false) => {
	let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
	if (!link) {
		link = document.createElement('link') as HTMLLinkElement;
		link.rel = 'icon';
		document.getElementsByTagName('head')[0].appendChild(link);
	}
	// link.href = `${HOST_URL}favicon-admin${env ? "-" + env : ""}.ico`;
	link.setAttribute('href', `${isAdmin ? '/admin' : ''}/favicon${isAdmin ? '-admin' : ''}${env ? '-' + env : ''}.ico`);
};

export const setFaviconByEnv = () => {
	if (Env.isProduction) return;

	const env = Env.current;
	let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
	if (!link) {
		link = document.createElement('link') as HTMLLinkElement;
		link.rel = 'icon';
		document.getElementsByTagName('head')[0].appendChild(link);
	}
	// link.href = `${HOST_URL}favicon-admin${env ? "-" + env : ""}.ico`;
	link.setAttribute(
		'href',
		`${Env.isAdmin ? '/admin' : ''}/favicon${Env.isAdmin ? '-admin' : ''}${env ? '-' + env : ''}.ico`
	);
};

export function round(number: number, numDigits = 0) {
	const exp = Math.pow(10, numDigits);
	return Math.round(number * exp) / exp;
}

export const K = 1024;
export const KB = K;
export const MB = K * KB;
export const GB = K * MB;
export const TB = K * GB;
export const PB = K * TB;

export function fileSize(sizeBytes: number) {
	if (sizeBytes === undefined || sizeBytes === null) return '?';
	if (sizeBytes < KB) return sizeBytes + ' bytes';
	const sizeEntries = Object.entries({ KB, MB, GB, TB, PB });
	for (let i = 0; i < sizeEntries.length; i++) {
		const [unitName, unitSize] = sizeEntries[i];
		if (sizeBytes < K * unitSize) {
			let num = Math.round((sizeBytes / unitSize) * 10) / 10;
			if (unitName === 'KB') num = Math.round(num);
			return `${num}${unitName}`;
		}
	}
	return sizeBytes;
}

export function fileSizeMega(sizeBytes: number) {
	if (sizeBytes === undefined || sizeBytes === null) return '?';
	const numDigits = sizeBytes < 0.1 * MB ? 2 : sizeBytes < 10 * MB ? 1 : 0;
	return round(sizeBytes / MB, numDigits) + ' MB';
}
export const VALID_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export function formatString(template: string, values: Record<string, any> = {}) {
	return template.replace(/\${([^{}]+)}/g, (match: string, key: string) =>
		typeof values[key] !== 'undefined' ? values[key] : match
	);
}

export const isEqualSets = (a: Set<any>, b: Set<any>) => {
	if (a.size !== b.size) return false;
	return Array.from(a).every((value) => b.has(value));
};

export function toFileArray(files: File | File[] | FileList | FormData): File[] {
	if (files instanceof FormData) {
		files = files.get('files') as unknown as FileList;
	}
	if (files instanceof File) return [files];
	if (files instanceof FileList) return Array.from(files);
	return files;
}

export function regexpAllMatches(str: string, regex: RegExp): RegExpExecArray[] {
	const res = [];
	let match;
	do {
		match = regex.exec(str);
		// @ts-ignore
		if (match) res.push(match);
	} while (match);
	return res;
}

export function sanitizeFilename(filename: string) {
	['\\', '/', '?', '<', '>', ':', '*', '|', '"'].forEach(
		(char) => (filename = filename.replace(new RegExp('\\' + char, 'g'), '\\' + char))
	);
	return filename;
}

export function formatDuration(secs: number) {
	if (secs === undefined || secs === null) return '?';
	return secs ? dayjs.duration(secs, 'seconds').format('HH:mm:ss') : '';
}

export function duration(milliseconds: number) {
	return dayjs.duration(milliseconds || 0);
}

export function colorStringToRgb(rgbString: string) {
	if (rgbString.startsWith('rgb(')) {
		return rgbString
			.substring(4, rgbString.length - 5)
			.split(',')
			.map((value: string) => parseInt(value));
	}
	rgbString = rgbString.replace('#', '');

	const partLen = rgbString.length / 3;
	return rgbString
		.match(new RegExp('(.{' + partLen + '})', 'g'))!
		.map((part) => parseInt(partLen === 1 ? part + part : part, 16));
}

export function getColorBrightness(rgbString: string) {
	const [r, g, b] = colorStringToRgb(rgbString);
	return (r * 299 + g * 587 + b * 114) / 1000 / 255;
}

export const MimeType = {
	word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export function fileNamesWithNumExtensionSortComparator(v1: string, v2: string) {
	const analyzeName = (v: string) => {
		const extension = path.extname(v);
		const name = v.slice(0, v.length - extension.length);
		const suffix = (name.match(/(\d+)$/) || [])[1] || '0';
		const baseName = name.slice(0, name.length - suffix.length);
		return { baseName, suffix };
	};
	const [name1, name2] = [v1, v2].map(analyzeName);
	if (name1.baseName !== name2.baseName) return name1.baseName.localeCompare(name2.baseName);
	return parseInt(name1.suffix) - parseInt(name2.suffix);
}

export function addSuffixToFilename(filename: string, suffix: string) {
	const { name, ext } = path.parse(filename);
	return name + suffix + ext;
}

export function getUniqueFilenamesByAddingNumericSuffix(existingFilenames: string[], newFilenames: string[]) {
	const replaceName: Record<string, string> = {};
	const usedFilenames = [...existingFilenames];
	newFilenames.forEach((filename) => {
		if (!usedFilenames.includes(filename)) {
			replaceName[filename] = filename;
		} else {
			for (let suffix = 1; suffix < 99999; suffix++) {
				const newFilename = addSuffixToFilename(filename, ` (${suffix})`);
				if (!usedFilenames.includes(newFilename)) {
					replaceName[filename] = newFilename;
					usedFilenames.push(filename);
					break;
				}
			}
		}
	});
	return replaceName;
}

export function toIsraelTime(date: Date | Dayjs) {
	return dayjs(date).tz('Pacific/Auckland');
}

export function getCallStack() {
	try {
		throw new Error('');
	} catch (e) {
		return (e as Error).stack?.split('\n').slice(2).join('\n');
	}
}

export function findUnusedValue(values: any[], candidateValues: any[]) {
	for (const index in candidateValues) {
		if (!values.includes(candidateValues[index])) return candidateValues[index];
	}
	return undefined;
}

export function valuesWithNumericSuffix(base: string, minSuffix: number, maxSuffix: number) {
	return range(minSuffix, maxSuffix + 1).map((suffix) => base + suffix.toString());
}

export function replaceInArr(arr: any[], matchProp: string, matchValue: string, newItem: any) {
	return arr.map((item) => (item[matchProp] === matchValue ? newItem : item));
}

export function isHebrewText(text: any) {
	return typeof text === 'string' && Boolean((text as string).match(/א-ת/)) && !(text as string).startsWith('{');
}

export function nounAndCount(count: number, singular: string, plural: string) {
	return count === 1 ? singular : `${count} ${plural}`;
}

export function addRandomStringToFilename(filename: string) {
	const { name, ext } = path.parse(filename);
	return name + '.' + randomString(4) + (ext || '');
}

export const COLOR_REGEX = /^((#[0-9a-f]{3})|#[0-9a-f]{6}|rgb\(\d+, ?\d+, ?\d+\)|rgba\(\d+, ?\d+, ?\d+, ?[\d\.]+\))$/;
