import { Axios } from 'axios';
import { getJwtHeader } from './authUtil';
import { apiServerUri } from '@/lib/utils';

export default function client(config?: any): Axios {
	return new Axios({
		baseURL: apiServerUri(),
		headers: getJwtHeader(),
		...config,
	});
}
