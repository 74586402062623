import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { INotification } from '@common/types/notification';
import { useAuthContext } from '@/lib/providers/AuthProvider';
import { getNotifications } from '@/api/server/user';

interface NotificationsContextType {
	notifications: INotification[];
	refreshNotifications?: () => void;
}

const NotificationsContext = createContext({
	notifications: [],
	refreshNotifications: undefined,
} as NotificationsContextType);

let timer: NodeJS.Timeout | undefined = undefined;

export function NotificationsProvider({ children }: { children: ReactElement }) {
	const { isLoggedIn, runApi } = useAuthContext();

	const [notifications, setNotifications] = useState([]);

	const refreshNotifications = useCallback(() => {
		return;
		if (isLoggedIn) {
			runApi(getNotifications).then(setNotifications);
		}
	}, [isLoggedIn, runApi]);

	function restartTimer() {
		if (timer) clearInterval(timer);
		timer = setInterval(refreshNotifications, 60000);
		refreshNotifications();
	}

	useEffect(restartTimer, [isLoggedIn, refreshNotifications]);

	if (!timer) restartTimer();

	return (
		<NotificationsContext.Provider value={{ notifications, refreshNotifications }}>
			{children}
		</NotificationsContext.Provider>
	);
}

export function useNotificationsContext() {
	return useContext(NotificationsContext);
}
