import { setLocale, string, date, ObjectSchema, object, boolean, number, InferType } from 'yup';
import { he } from 'yup-locales';

setLocale(he);

export const BaseSchema = {
	id: string().required().min(24).max(24),
	createdAt: date().label('נוצר').min(new Date(2020, 0, 1)).max(new Date(2040, 0, 1)),
	updatedAt: date().label('עודכן').min(new Date(2020, 0, 1)).max(new Date(2040, 0, 1)),
};

export interface BaseFieldDef {
	name: string;
	label: string;
	type: string;
	oneOf?: string[];
	describe?: () => BaseFieldDef;
}

export const NEW_OBJECT_ID = '000000000000000000000000'; // length needs to be exactly 24 chars

export function convertOptionalSchemaFieldsToNullable(schema: ObjectSchema<any>) {
	Object.entries(schema.describe().fields).forEach(([name, field]) => {
		if ((field as any).optional) {
			(schema.fields as any)[name] = ((schema.fields as any)[name] as any).nullable();
		}
	});
}

export const dixilangAutoTranscriptionConfigSchema = object().shape({
	separateChannels: boolean().optional().label('הפרדת ערוצים'),
	speakerDiarization: boolean().optional().label('הפרדת דוברים'),
	numSpeakers: number().optional().label('מספר דוברים'),
});

export type IDixilangAutoTranscriptionConfig = InferType<typeof dixilangAutoTranscriptionConfigSchema>;

export interface FieldVisualSettings {
	width?: number;
	i18nPrefix?: string;
	align?: 'left' | 'right' | 'center';
	precision?: number;
	readonly?: boolean;
	longText?: boolean;
	format?: string;
	label?: string;
	ltr?: boolean;
	background?: (value: any) => string;
	tooltip?: (value: any) => string;
	valueGetter?: (value: any) => string;
}

export type FieldsVisualSettings = Record<string, FieldVisualSettings>;

export const COMMON_FIELDS_VISUAL_SETTINGS: FieldsVisualSettings = {
	name: { width: 160 },
	displayName: { width: 200, align: 'left', ltr: true },
	status: { width: 120, i18nPrefix: 'STATUS' },
	chargeAmount: { precision: 2 },
	chargeByHour: { precision: 2 },
	chargeByWord: { precision: 2 },
	audioDuration: { precision: 2, readonly: true },
	assignedAt: { readonly: true },
	editor: { width: 120 },
	downlodTranscription: { width: 40 },
};

export const CommonFields: Record<string, BaseFieldDef> = {
	transcriptionist: { name: 'transcriptionist', label: 'מתמלל', type: 'string' },
	editor: { name: 'editor', label: 'מטייב', type: 'string' },
};
