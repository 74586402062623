import classnames from 'classnames';
import { compact, flatten, isEmpty } from 'lodash';
import Link from 'next/link';
import { ReactElement, useState } from 'react';
import { Box, Divider, List, ListItem as BaseListItem, Radio } from '@mui/material';
import { styled, SxProps } from '@mui/system';

import theme from '@/styles/theme';
import { useAuthContext } from '@/lib/providers/AuthProvider';
import {
	Folder,
	PeopleAlt,
	CalendarMonth,
	ContentCopyOutlined,
	Update,
	FolderSpecial,
	FormatListBulleted,
	AccountCircle,
	Search,
} from '@mui/icons-material';
import { useVisualLayoutContext } from '@/lib/providers/VisualLayoutProvider';
import {
	ADMIN_HOLIDAYS_PAGE_PATH,
	ADMIN_JOBS_PAGE_PATH,
	ADMIN_USERS_PAGE_PATH,
	MANAGER_RECORDINGS_PATH,
	TRANSCRIPTIONST_FILES_PATH,
	ADMIN_PROJECTS_PAGE_PATH,
	ADMIN_LOGS_PAGE_PATH,
	ADMIN_CUSTOMERS_PAGE_PATH,
	CUSTOMER_PROJECTS_PAGE_PATH,
	CUSTOMER_SEARCH_PAGE_PATH,
	MANAGER_FILES_PATH,
} from '@/lib/paths';
import { UserRole } from '@common/types/User';

import { isExperimental } from '@/lib/providers/AuthProvider';

const { ADMIN, MANAGER, TRANSCRIPTIONIST, EDITOR, CUSTOMER } = UserRole;

const NavDivider = styled(Divider)({
	margin: '1rem 0',
	borderColor: theme.palette.text.secondary,
});

export const NavbarBox = styled(Box)({
	background: theme.palette.primary.main,
	position: 'fixed',
	height: '100vh',
	color: theme.palette.primary.contrastText,
	'& .MuiFormLabel-root': {
		lineHeight: '2rem',
	},
	'.title': {
		padding: '0 1rem',
		color: theme.palette.primary.contrastText,
	},
});

export const NavbarRadio = styled(Radio)({
	paddingTop: '0.3rem',
	paddingBottom: '0.3rem',
	'&.Mui-checked': {
		color: theme.palette.text.secondary,
	},
});

const ListItem = styled(BaseListItem)({
	backgroundColor: theme.palette.primary.light,
	'&.active': {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.dark,
	},
	'&:hover:not(.title)': {
		backgroundColor: theme.palette.primary.light,
	},
	a: {
		textDecoration: 'none',
	},
});

const SubItem = styled(ListItem)({
	backgroundColor: theme.palette.primary.light,
	fontSize: '80%',
	paddingRight: '2rem',
});

const NavLink = styled(Link)({
	width: '100%',
	height: '100%',
	lineHeight: '2.5rem',
	padding: '0 1rem',
	display: 'flex',
	alignItems: 'center',
	gap: '0.5rem',
	textDecoration: 'none',
	color: theme.palette.text.secondary + ' !important',
	'&.active': {
		color: theme.palette.primary.contrastText + ' !important',
	},
	svg: {
		fontSize: '1.2rem',
	},
});

const SelectedListItem = styled(ListItem)({
	background: theme.palette.primary.light,
});

interface NavItem {
	icon?: ReactElement;
	text: string;
	link?: string;
	subItems?: NavItem[];
	roles: string[];
	isTitle?: boolean;
}

const SEPARATOR = '-';

const items: NavItem[] = compact([
	isExperimental() && { text: 'מנהל', roles: [ADMIN, MANAGER], isTitle: true },
	{ icon: <Folder />, text: 'הקלטות', link: MANAGER_RECORDINGS_PATH, roles: [MANAGER, ADMIN] },
	{ icon: <Folder />, text: 'קבצים', link: MANAGER_FILES_PATH, roles: [MANAGER, ADMIN] },
	//{ icon: <Equalizer />, text: 'דשבורד', link: MANAGER_DASHBOARD_PATH, roles: [MANAGER, ADMIN] },
	{ text: SEPARATOR, roles: [ADMIN, MANAGER] },
	{ text: 'תצוגת משתמש', roles: [ADMIN, MANAGER], isTitle: true },
	{
		icon: <ContentCopyOutlined />,
		text: 'קבצים לטיוב',
		link: TRANSCRIPTIONST_FILES_PATH,
		roles: [EDITOR, TRANSCRIPTIONIST, MANAGER, ADMIN],
	},
	isExperimental() && {
		icon: <FolderSpecial />,
		text: 'פרויקטים',
		link: CUSTOMER_PROJECTS_PAGE_PATH,
		roles: [ADMIN, MANAGER, CUSTOMER],
	},
	isExperimental() && {
		icon: <Search />,
		text: 'חיפוש',
		link: CUSTOMER_SEARCH_PAGE_PATH,
		roles: [ADMIN, MANAGER, CUSTOMER],
	},
	{ text: SEPARATOR, roles: [ADMIN] },
	{ text: 'הגדרות', roles: [ADMIN], isTitle: true },
	{ icon: <PeopleAlt />, text: 'משתמשים', link: ADMIN_USERS_PAGE_PATH, roles: [ADMIN] },
	{ icon: <AccountCircle />, text: 'לקוחות', link: ADMIN_CUSTOMERS_PAGE_PATH, roles: [ADMIN] },
	isExperimental() && {
		icon: <FolderSpecial />,
		text: 'פרויקטים',
		link: ADMIN_PROJECTS_PAGE_PATH,
		roles: [MANAGER, ADMIN],
	},
	{ icon: <CalendarMonth />, text: 'ימי חופש', link: ADMIN_HOLIDAYS_PAGE_PATH, roles: [ADMIN] },
	{ icon: <Update />, text: 'משימות רקע', link: ADMIN_JOBS_PAGE_PATH, roles: [ADMIN] },
	{ icon: <FormatListBulleted />, text: 'לוג', link: ADMIN_LOGS_PAGE_PATH, roles: [ADMIN] },
]);

const Navbar = ({ sx }: { sx: SxProps }) => {
	const [selectedItem, setselectedItem] = useState<string | undefined>(undefined);

	const { navbarComponents } = useVisualLayoutContext();
	const { canAccessPage, user } = useAuthContext();

	const onClick = (item: NavItem) => {
		return item.subItems && { onClick: () => setselectedItem(item.text) };
	};

	return (
		<NavbarBox sx={{ ...sx }}>
			<List>
				{items.map((item, index) => {
					if (!user?.role) return null;
					if (!isEmpty(item.roles) && !item.roles.includes(user!.role)) return null;
					if (item.link && !canAccessPage(item.link)) return null;
					if (item.text === SEPARATOR)
						return (
							<Box
								key={index.toString()}
								sx={{ height: 15 }}
							/>
						);
					const selected = item.text === selectedItem;
					const ItemClass = selected ? SelectedListItem : ListItem;
					const isCurrent = new URL(location.href).pathname === item.link;
					const backgroundColor = isCurrent ? theme.palette.primary.main : 'inherit';
					const color = isCurrent ? theme.palette.primary.contrastText : 'inherit';

					return (
						<div key={item.text + index.toString()}>
							<ItemClass
								sx={{ backgroundColor, padding: 0, height: '2.5rem' }}
								className={classnames(isCurrent && 'active', item.isTitle && 'title')}
							>
								{item.isTitle ? (
									item.text
								) : (
									<NavLink
										href={item.link || '#'}
										sx={{
											color,
										}}
										className={isCurrent ? 'active' : ''}
										{...onClick(item)}
									>
										{item.icon}
										{item.text}
									</NavLink>
								)}
							</ItemClass>
							{selected &&
								(item.subItems || []).map((subItem) => (
									<SubItem key={item.text}>
										<Link
											href={subItem.link || '#'}
											color={theme.palette.primary.light}
										>
											{subItem.text}
										</Link>
									</SubItem>
								))}
						</div>
					);
				})}
			</List>
			{navbarComponents && (
				<Box sx={{ padding: '1rem', color: theme.palette.text.secondary }}>
					{flatten([navbarComponents]).map((component, index) => [<NavDivider key={index.toString()} />, component])}
				</Box>
			)}
		</NavbarBox>
	);
};

export default Navbar;
