/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Badge, Box, IconButton, MenuItem, SxProps, Toolbar } from '@mui/material';
import { NotificationsOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';

import theme from '@/styles/theme';
import { useVisualLayoutContext } from '@/lib/providers/VisualLayoutProvider';
import { useNotificationsContext } from '@/lib/providers/NotificationsProvider';
import { useAuthContext } from '@/lib/providers/AuthProvider';
import TopBarMenu from './TopBarMenu';
import Avatar from '../Avatar';

export const TOP_BAR_HEIGHT = '4rem';

const MenuItemText = styled(Box)({
	maxWidth: '12rem',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	'&.unread': {
		fontWeight: 'bold',
	},
});

const Title = styled('span')({
	color: theme.palette.primary.main,
});

// Not using MUI Breadcrubs since it uses MUI Link which doesnt play nice with nextjs
const Breadcrumb = styled(Link)({
	color: theme.palette.primary.main,
	textDecoration: 'none',
	marginRight: '1rem',
	'&:hover': {
		textDecoration: 'underline',
	},
});

export default function TopBar({ sx }: { sx: SxProps }) {
	const { logout, user } = useAuthContext();
	const { title, breadcrumbs } = useVisualLayoutContext();
	const router = useRouter();
	const { notifications } = useNotificationsContext();

	return (
		<Box
			sx={{
				height: TOP_BAR_HEIGHT,
				width: '100%',
				zIndex: 999,
				backgroundColor: theme.palette.primary.contrastText,
				display: 'flex',
				position: 'fixed',
				top: 0,
				...sx,
			}}
		>
			<Toolbar
				sx={{
					display: 'flex',
					width: '100%',
					gap: '1rem',
					marginRight: '3rem',
					height: TOP_BAR_HEIGHT,
				}}
			>
				<img
					alt="לוגו"
					src="/logo.png"
					height="50%"
					style={{ cursor: 'pointer', marginLeft: '5rem' }}
					onClick={() => router.push('/')}
				/>
				{breadcrumbs.map((breadcrumb) => (
					<Box
						sx={{ color: theme.palette.grey[500] }}
						key={breadcrumb.pathname}
					>
						<Breadcrumb href={breadcrumb.pathname}>{breadcrumb.title}</Breadcrumb>/
					</Box>
				))}
				<Title>{title}</Title>
				<Box sx={{ flexGrow: 1 }}></Box>
				{/*<TopBarMenu
					id="notifications-menu"
					anchor={
						<IconButton>
							<Badge
								invisible={notifications?.length === 0}
								color="secondary"
								badgeContent={notifications?.filter((n) => !n.read).length}
							>
								<NotificationsOutlined color="primary" />
							</Badge>
						</IconButton>
					}
				>
					{[
						...(notifications || [])
							.sort((n1, n2) => n2.createdAt!.valueOf() - n1.createdAt!.valueOf())
							.slice(0, 5)
							.map((notification) => (
								<MenuItem key={notification.id}>
									<MenuItemText className={!notification.read ? 'unread' : ''}>{notification.text}</MenuItemText>
								</MenuItem>
							)),
						notifications?.length ? (
							<MenuItem
								key="-"
								divider
							/>
						) : null,
						<MenuItem
							key="all"
							onClick={() => router.push('/notifications')}
						>
							כל ההודעות
						</MenuItem>,
					]}
				</TopBarMenu>*/}
				<TopBarMenu
					id="user-menu"
					anchor={
						<IconButton>
							<Avatar
								user={user}
								size="2rem"
							/>
						</IconButton>
					}
				>
					{/*<MenuItem>פרופיל</MenuItem>*/}
					<MenuItem onClick={logout}>יציאה</MenuItem>
				</TopBarMenu>
			</Toolbar>
		</Box>
	);
}
