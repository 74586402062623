/* eslint-disable @next/next/no-img-element */
import theme from '@/styles/theme';
import { Box, Typography } from '@mui/material';
import Link from 'next/link';

export default function Error({ exception }: { exception?: any }) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<img
				alt="לוגו"
				src="/logo.png"
				style={{ height: '4rem' }}
			/>
			<Typography style={{ fontSize: '3rem', fontWeight: 'bold' }}>סליחה, תקלה</Typography>
			<p>{JSON.stringify(exception)}</p>
			<Box sx={{ display: 'flex', fontSize: '1.5rem' }}>
				<Link
					href="javascript:location.reload()"
					style={{ color: theme.palette.primary.main }}
				>
					נסה שוב
				</Link>
				<span style={{ margin: '0 1rem' }}>או</span>
				<Link
					href="/"
					style={{ color: theme.palette.primary.main }}
				>
					חזרה לעמוד הראשי
				</Link>
			</Box>
		</Box>
	);
}
