import { formatString } from '@common/misc';
import { IRecording } from '@common/types/Recording';

// including export consts in .tsx files cause nextjs to refresh page on any change to the file
// instead bringing them all here

export const PATH_NEW_OBJECT_ID = 'new';

export const LOGIN_PAGE_PATH = '/login';
export const ERROR_PAGE_PATH = '/500';
export const UNKNOWN_PAGE_PATH = '/404';

export const CHANGE_PASSWORD_PAGE_PATH = '/change-password';

export const TRANSCRIPTIONST_FILES_PATH = '/transcriptionist/files';

export const MANAGER_DASHBOARD_PATH = '/manager/dashboard';
export const MANAGER_RECORDINGS_PATH = '/manager/recordings';
export const MANAGER_FILES_PATH = '/manager/files';

export const ADMIN_HOLIDAYS_PAGE_PATH = '/admin/holidays';
export const ADMIN_JOBS_PAGE_PATH = '/admin/jobs';
export const ADMIN_LOGS_PAGE_PATH = '/admin/logs';

export const ADMIN_USERS_PAGE_PATH = '/admin/users';
export const ADMIN_USER_PAGE_PATH = '/admin/users/${id}';
export const ADMIN_NEW_USER_PAGE_PATH = formatString(ADMIN_USER_PAGE_PATH, { id: 'new' });
export const ADMIN_USERS_PAGE_BREADCRUMB = { title: 'משתמשים', pathname: ADMIN_USERS_PAGE_PATH };
export const ADMIN_USER_PAGE_BREADCRUMB = { title: 'משתמש חדש', pathname: ADMIN_USER_PAGE_PATH };

export const ADMIN_CUSTOMERS_PAGE_PATH = '/admin/customers';
export const ADMIN_CUSTOMER_PAGE_PATH = '/admin/customers/${id}';
export const ADMIN_NEW_CUSTOMER_PAGE_PATH = formatString(ADMIN_CUSTOMER_PAGE_PATH, { id: 'new' });
export const ADMIN_CUSTOMERS_PAGE_BREADCRUMB = { title: 'לקוחות', pathname: ADMIN_CUSTOMERS_PAGE_PATH };
export const ADMIN_CUSTOMER_PAGE_BREADCRUMB = { title: 'לקוח חדש', pathname: ADMIN_CUSTOMER_PAGE_PATH };

export const ADMIN_PROJECTS_PAGE_PATH = '/admin/projects';
export const ADMIN_PROJECT_PAGE_PATH = '/admin/projects/${id}';
export const ADMIN_NEW_PROJECT_PAGE_PATH = formatString(ADMIN_PROJECT_PAGE_PATH, { id: 'new' });
export const ADMIN_PROJECTS_PAGE_BREADCRUMB = { title: 'פרויקטים', pathname: ADMIN_PROJECTS_PAGE_PATH };
export const ADMIN_PROJECT_PAGE_BREADCRUMB = { title: 'פרויקט חדש', pathname: ADMIN_PROJECT_PAGE_PATH };

export const CUSTOMER_PROJECTS_PAGE_PATH = '/customer/projects';
export const CUSTOMER_PROJECTS_PAGE_BREADCRUMB = { title: 'פרויקטים', pathname: CUSTOMER_PROJECTS_PAGE_PATH };

export const CUSTOMER_SEARCH_PAGE_PATH = '/customer/search';
export const CUSTOMER_SEARCH_PAGE_BREADCRUMB = { title: 'חיפוש', pathname: CUSTOMER_SEARCH_PAGE_PATH };

export const CUSTOMER_PROJECT_PAGE_PATH = '/customer/projects/${id}';
export const CUSTOMER_PROJECT_PAGE_BREADCRUMB = (recording?: IRecording) => ({
	title: recording?.name || '',
	pathname: formatString(CUSTOMER_PROJECT_PAGE_PATH, { id: recording?.id }),
});

export const CUSTOMER_FILE_PAGE_PATH = '/customer/files/${id}';
