import React, { ReactElement, useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import { Box } from '@mui/system';

export default function TopBarMenu({
	id,
	anchorEl,
	children,
	onClose,
}: {
	id: string;
	anchorEl: Element | null | undefined;
	children: ReactElement | (ReactElement | null)[];
	onClose: () => void;
}) {
	return (
		<Menu
			id={id}
			aria-labelledby={id}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
					mt: 2,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 'calc(50% - 5px)',
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0,
					},
				},
			}}
		>
			{children}
		</Menu>
	);
}
