import { ApolloClient, InMemoryCache, createHttpLink, from, gql, ApolloError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getJwtHeader } from './authUtil';
import { isTx, txWithPrefix } from '@/localization/i18n';
import { apiServerUri } from '@/lib/utils';
import { isHebrewText } from '@common/misc';
// import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		);
	if (networkError) console.error(`[Network error]: ${networkError}`);
});

// Add JWT to header
const authLink = setContext((_, { headers }) => {
	return { headers: { ...headers, ...getJwtHeader() } };
});

const serverUri = apiServerUri();
const uri = new URL('/graphql', serverUri).toString();
const httpLink = createHttpLink({ uri, credentials: 'include' });

const client = () =>
	new ApolloClient({
		link: from([errorLink, authLink.concat(httpLink)]),
		cache: new InMemoryCache({ possibleTypes: {}, addTypename: false }),
	});

export const query = async (queryString: string, variables?: Object) => {
	const query = gql(`query ${queryString}`);
	const res = await client().query({ query, variables, fetchPolicy: 'no-cache' });
	return res.data;
};

export function getApolloError(ex: any, raw?: boolean) {
	if (isTx(ex.message) || isHebrewText(ex.message)) {
		return raw ? ex.message : txWithPrefix(ex.message);
	}
	if (!(ex instanceof ApolloError)) {
		return ex.stack?.substr(0, 300) || ex.message || ex || '';
	}
	const { networkError } = ex;
	if (networkError) {
		const { result } = networkError as any;
		if (result?.errors) {
			if (result.errors[0].message.includes('on type "Query"')) {
				console.log('New query? Maybe restart server');
				debugger;
			}
			return result.errors.map((err: any) => err.message); //.join('\n');
		}
		return networkError.message;
	}
	return ex;
}

export default client;
