import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const BACKGROUND_COLOR = '#f8f8f8';
export const PRIMARY_COLOR = '#2c98d2';
export const SECONDARY_COLOR = '#f37341';
export const SECONDARY_CONTRAST_COLOR = 'white';

const FONT = 'Open Sans Hebrew';

const theme = createTheme({
	direction: 'rtl',
	palette: {
		primary: {
			main: PRIMARY_COLOR,
			light: '#67bfe0',
			dark: '#444',
			contrastText: '#fff',
		},
		secondary: {
			main: SECONDARY_COLOR,
			contrastText: SECONDARY_CONTRAST_COLOR,
		},
		text: {
			primary: '#000',
			secondary: 'rgba(255, 255, 255, 0.7)',
			disabled: '#aaa',
		},
		grey: {
			50: 'rgba(0, 0, 0, 0.03)',
			200: 'rgba(0, 0, 0, 0.2)',
			300: 'rgba(0, 0, 0, 0.3)',
			400: 'rgba(0, 0, 0, 0.4)',
			500: 'rgba(0, 0, 0, 0.5)',
		},
	},
	typography: {
		fontFamily: FONT,
		fontSize: 14,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '100%',
				},
			},
			variants: [
				{
					props: { size: 'large' },
					style: {
						fontSize: '120%',
					},
				},
				{
					props: { color: 'secondary', size: 'large', variant: 'outlined' },
					style: {
						borderColor: grey[500],
						color: SECONDARY_COLOR,
						background: SECONDARY_CONTRAST_COLOR,
						'&:hover': {
							borderColor: SECONDARY_COLOR,
							backgroundColor: SECONDARY_COLOR,
							color: SECONDARY_CONTRAST_COLOR,
						},
					},
				},
			],
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					alignItems: 'center',
					'& .MuiAlert-icon': {
						marginRight: '5px',
					},
					'& .MuiAlert-message': {
						textAlign: 'left', // RTL plugin switches left and right
					},
				},
			},
		},
		MuiCheckbox: {
			/* props: {
				variant: 'editor',
				icon: <Check />,
				checkedIcon: <Check />,
			}, */
			styleOverrides: {
				root: {
					color: 'rgba(0, 0, 0, 0.1)',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					backgroundColor: 'white',
					input: {
						fontSize: '1rem',
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					input: {
						backgroundColor: 'white',
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					backgroundColor: 'white',
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					minHeight: 'auto',
					'@media (min-width: 600px)': {
						minHeight: 'auto',
					},
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: 'black',
				},
			},
		},
	},
});
theme.shadows.push('0 10px 50px 0px rgb(0 0 0 / 10%)');

export enum Shadows {
	LargeDialog = 25,
}

export default theme;
