import React, { ReactElement, useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import { Box } from '@mui/system';
import PopupMenu from '../PopupMenu';

export default function TopBarMenu({
	id,
	anchor,
	children,
}: {
	id: string;
	anchor: ReactElement | ReactElement[];
	children: ReactElement | (ReactElement | null)[];
}) {
	const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>();

	return (
		<>
			<Box
				sx={{ cursor: 'pointer' }}
				onClick={(e) => setAnchorElement(e.target as HTMLElement)}
			>
				{anchor}
			</Box>
			<PopupMenu
				id={id}
				aria-labelledby={id}
				anchorEl={anchorElement}
				onClose={() => setAnchorElement(undefined)}
			>
				{children}
			</PopupMenu>
		</>
	);
}
