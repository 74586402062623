// ENV

export const APP_NAME = 'textify';
export const PRODUCTION_DOMAIN = 'hever-textify.com';
export const PRODUCTION_SERVER_SUBDOMAIN = 'api2';
export const PRODUCTION_FRONTEND_SUBDOMAIN = 'admin';

export const STAGING_SUBDOMAIN = 'staging.' + PRODUCTION_FRONTEND_SUBDOMAIN;
export const TESTING_SUBDOMAIN = 'testing.' + PRODUCTION_FRONTEND_SUBDOMAIN;

export const DEV_FRONTEND_PORT = 3001;
export const DEV_BACKEND_PORT = 8001;

export const Env = {
	PRODUCTION: 'production',
	STAGING: 'staging',
	TESTING: 'test',
	DEVELOPMENT: 'development',
	get: (): string => {
		// infer from env vars
		const envVar = process.env.NODE_ENV || process.env.NEXT_PUBLIC_NODE_ENV;
		if (envVar) return envVar;

		// if in browser - infer from current URL
		if (typeof window !== 'undefined') {
			const { host } = window.location;
			if (host === `${PRODUCTION_FRONTEND_SUBDOMAIN}.${PRODUCTION_DOMAIN}`) {
				return Env.PRODUCTION;
			}
			if (host.startsWith(STAGING_SUBDOMAIN)) return Env.STAGING;
			if (host.startsWith(TESTING_SUBDOMAIN)) return Env.TESTING;
			if (host.startsWith('localhost:')) return Env.DEVELOPMENT;
			return Env.TESTING;
		}

		// otherwise default to development
		return Env.DEVELOPMENT;
	},
	current: '' as string,
	isProduction: false,
	isStaging: false,
	isDevelopment: false,
	isTesting: false,
	isAdmin: false,
	serverUrl: '' as string,
	clientUrl: '' as string,
	isClient: typeof window !== 'undefined',
	isServer: typeof window === 'undefined',
};

try {
	Env.current = Env.get();
	Env.isProduction = Env.current === Env.PRODUCTION;
	Env.isDevelopment = Env.current === Env.DEVELOPMENT;
	Env.isStaging = Env.current === Env.STAGING;
	Env.isTesting = Env.current === Env.TESTING;

	const regionPrefix = Env.isClient ? (window.location.host.startsWith('il.') ? 'il.' : '') : process.env.REGION_PREFIX;

	Env.serverUrl = Env.isDevelopment
		? `http://localhost:${DEV_BACKEND_PORT}`
		: `https://${regionPrefix}${PRODUCTION_SERVER_SUBDOMAIN}.${PRODUCTION_DOMAIN}`;

	Env.clientUrl = Env.isClient
		? window.location.origin
		: Env.isDevelopment
		? `http://localhost:${DEV_FRONTEND_PORT}`
		: `https://${regionPrefix}${PRODUCTION_FRONTEND_SUBDOMAIN}.${PRODUCTION_DOMAIN}`;
} catch (e) {
	console.log(e);
}
