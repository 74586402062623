import { CircularProgress } from '@mui/material';
import MidScreenLayout from './MidScreenLayout';

export default function LoadingScreen() {
	return (
		<MidScreenLayout>
			<CircularProgress />
		</MidScreenLayout>
	);
}
