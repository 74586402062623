import { Axios, AxiosRequestConfig } from 'axios';
import { ReactNode } from 'react';
import { IFile } from '@common/types/File';
import { RecordingCustomerDefs } from '@common/types/Recording/customers/types';
import { Env } from '@common/env';
import { toFileArray } from '@common/misc';

export function apiServerUri() {
	return Env.serverUrl;
	let res = process.env.NEXT_PUBLIC_API_SERVER_URI;
	if (!res) {
		res =
			process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? 'http://localhost:8001' : 'https://api2.hever-textify.com/';
	}
	return res;
}

export async function uploadFiles(
	client: Axios,
	url: string,
	files: File | File[] | FileList | FormData,
	extraData?: any
) {
	let formData: FormData;
	if (files instanceof FormData) {
		formData = files;
	} else {
		formData = new FormData();
		const filesArray = toFileArray(files);
		filesArray.forEach((file) => formData.append(`files`, file));
	}

	Object.entries(extraData || {}).forEach(([key, value]) => {
		formData.append(key, value as string | Blob);
	});

	const options: AxiosRequestConfig = {
		headers: { 'Content-Type': 'multipart/form-data' },
		responseType: 'json',
	};

	const res = await client.post(url, formData, options);
	const toJson = (data: any) => (typeof res.data === 'string' ? JSON.parse(data) : data);

	let { data } = res;
	try {
		data = toJson(data);
	} catch {}
	if (res.status >= 300) throw new Error(data?.error || data);
	return data;
}

export function getFileAsBlobUrl(files: any): Promise<string> {
	return new Promise<any>((resolve: any) => {
		if (!files) return;
		const fr = new FileReader();

		fr.onload = function () {
			const blob = new Blob([fr.result!]);
			const url = URL.createObjectURL(blob);
			resolve(url);
		};

		fr.readAsArrayBuffer(files instanceof FileList ? files[0] : files);
	});
}

export function stringToArrayBuffer(s: string) {
	const buffer = new ArrayBuffer(s.length);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < s.length; i++) {
		view[i] = s.charCodeAt(i) & 0xff;
	}
	return buffer;
}

export function getFileAsBase64(files: any): Promise<string | null> {
	return new Promise<any>((resolve: any) => {
		if (!files) return resolve(null);

		const fr = new FileReader();
		fr.onload = () => resolve(fr.result!);
		fr.readAsDataURL(files instanceof FileList ? files[0] : files);
	});
}

export function downloadBlobAsFilename(blob: Blob, filename: string) {
	const url = window.URL.createObjectURL(blob);
	const linkElement = document.createElement('a');
	linkElement.href = url;
	linkElement.download = filename;
	document.body.appendChild(linkElement);
	linkElement.click();
	linkElement.remove();
}

export function textDirection(text: ReactNode) {
	if (typeof text === 'string') {
		const hasEnglish = (text || '').toString().match(/[a-zA-Z]/);
		if (hasEnglish) return 'rtl';
	}
	return 'ltr';
}

export function describeFile(file: IFile, customerDefs: RecordingCustomerDefs) {
	if (!file) return '';
	const extraData = file.recording.extraData;
	return customerDefs.transcriptionistFields.map((field) => extraData[field]).join(' | ');
}

export function alert(something: any) {
	if (typeof window !== 'undefined') {
		window.alert(something);
	}
}
