import React, { useRef } from 'react';
import { createContext, ReactElement, useContext, useState } from 'react';
import { IUser } from '@common/types/User';
import { IRecording } from '@common/types/Recording';
import { keyBy, map, pullAllBy, remove, zipObject } from 'lodash';
import { IFile } from '@common/types/File';
import { RunApiFunction } from './AuthProvider';

type CollectionName = 'recordings' | 'users' | 'files';

export interface DataContextType {
	recordings: IRecording[];
	files: IFile[];
	users: IUser[];
	currentFile?: IFile;
	//setCurrentFile: (file?: IFile);
	updateGlobalData: (collection: CollectionName, items: any[], removeIds?: string[], reset?: boolean) => void;
}

export const DataContext = createContext({} as DataContextType);

export function DataProvider({ children }: { children: ReactElement }) {
	const [recordings, setRecordings] = useState<IRecording[]>([]);
	const [files, setFiles] = useState<IFile[]>([]);
	const [users, setUsers] = useState<IUser[]>([]);
	//const [currentFile, setCurrentFile] = useState<IFile | undefined>();

	function updateGlobalData(collectionName: CollectionName, newItems: any[], removeIds: string[] = [], reset = false) {
		newItems = newItems || [];
		const currentItems: any[] = reset ? [] : { recordings, users, files }[collectionName];
		const setter = { recordings: setRecordings, users: setUsers, files: setFiles }[collectionName];

		const currentItemById = keyBy(currentItems, 'id');

		const newItemById = keyBy(newItems, 'id');
		const fixedItems = currentItems.map((item) => newItemById[item.id] || item);
		remove(fixedItems, (item) => removeIds.includes(item.id));

		const addItems = newItems.filter((item) => !(item.id in currentItemById));

		setter(addItems.concat(fixedItems));
	}

	// return <DataContext.Provider value={{ recordings, users, updateGlobalData, currentFile, setCurrentFile }}>{children}</DataContext.Provider>;
	return <DataContext.Provider value={{ recordings, files, users, updateGlobalData }}>{children}</DataContext.Provider>;
}

export function useDataContext() {
	return useContext(DataContext);
}
