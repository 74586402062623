import { toFormData } from 'axios';
import axios from '@/api/server/axios';
import { createContext, ReactElement, useContext, useState } from 'react';
import { AlertColor } from '@mui/material';
import Dialog, { DialogResponse, DialogProps } from '@/components/Dialog';
import { VisualCue } from '@/components/layout/VisualCues';
import { currentUser } from './AuthProvider';

export interface Breadcumb {
	title: string;
	pathname: string;
}

export type CueFunction = (
	type: AlertColor,
	text: string | string[] | Error,
	details?: string | string[] | Error,
	context?: string,
	extraData?: any
) => void;

export type ShowDialogFunction = (props: DialogProps) => Promise<any>;

interface VisualLayoutContextType {
	title: string;
	setTitle: (title: string) => void;
	breadcrumbs: Breadcumb[];
	setBreadcrumbs: (breadcrumbs: Breadcumb[]) => void;
	cues: VisualCue[];
	cue: CueFunction;
	removeCue: (cue: VisualCue) => void;
	clearCues: () => void;
	clearCueContext: (context: string) => void;
	setDialog: (props: DialogProps) => void;
	showDialog: ShowDialogFunction;
	navbarComponents?: ReactElement[];
	setNavbarComponents: (components?: ReactElement[]) => void;
}

const VisualLayoutContext = createContext({} as VisualLayoutContextType);

export function VisualLayoutProvider({ children }: { children: ReactElement }) {
	const [title, setTitle] = useState('');
	const [cues, setCues] = useState<VisualCue[]>([]);
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcumb[]>([]);
	const [dialog, setDialog] = useState<DialogProps | undefined>();
	const [navbarComponents, setNavbarComponents] = useState<ReactElement[] | undefined>();

	function cue(
		type: AlertColor,
		text: string | string[] | Error,
		details?: string | string[] | Error,
		context?: string,
		extraData?: any
	) {
		if (text instanceof Error) text = text.message;
		if (details instanceof Error) details = details.stack;
		const remainingCues = cues.filter((cue) => !context && cue.context === context);
		setCues([...remainingCues, { id: Math.random().toString(), type, text, details, context }]);
		if (type === 'error') {
			axios()
				.post('/misc/clientError', JSON.stringify({ text, details, extraData, user: currentUser()?.email }), {
					headers: { 'Content-Type': 'application/json' },
				})
				.catch((err) => console.error('Failed to send error to server', err));
		}
	}

	function removeCue(cue: VisualCue) {
		setCues(cues.filter((c) => c.id !== cue.id));
	}

	function clearCues() {
		setCues([]);
	}

	function clearCueContext(context: string) {
		setCues(cues.filter((c) => c.context !== context));
	}

	function handleCloseDialog(response: DialogResponse) {
		setDialog(undefined);
		dialog?.onClose && dialog?.onClose(response);
	}

	function showDialog(props: DialogProps): Promise<DialogResponse> {
		return new Promise((resolve) => {
			setDialog({
				...props,
				onClose: (response) => {
					setDialog(undefined);
					resolve(response);
				},
			});
		});
	}

	return (
		<VisualLayoutContext.Provider
			value={{
				cues,
				cue,
				removeCue,
				clearCues,
				clearCueContext,
				title,
				setTitle,
				breadcrumbs,
				setBreadcrumbs,
				setDialog,
				showDialog,
				navbarComponents,
				setNavbarComponents,
			}}
		>
			{children}
			{dialog && (
				<Dialog
					{...dialog}
					onClose={handleCloseDialog}
					onApprove={dialog.onApprove}
				/>
			)}
		</VisualLayoutContext.Provider>
	);
}

export function useVisualLayoutContext() {
	return useContext(VisualLayoutContext);
}
