import { Suspense, ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Navbar from './navbar';
import Footer from './footer';
import VisualCues from './VisualCues';
import TopBar, { TOP_BAR_HEIGHT } from './TopBar';

function Contents(children: ReactElement) {
	return <Suspense fallback="טוען...">{children}</Suspense>;
}

const NAVBAR_WIDTH = '13rem';

const SHADOW_SIZE = '5px';
const SHADOW_COLOR = 'rgba(0,0,0,0.1)';

export default function NavbarLayout(children: ReactElement) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				flexDirection: 'row',
				height: '100vh',
				width: '100vw',
				marginTop: TOP_BAR_HEIGHT,
			}}
		>
			<TopBar
				sx={{
					boxShadow: `0 ${SHADOW_SIZE} ${SHADOW_SIZE} 0 ${SHADOW_COLOR}`,
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					flexGrow: 1,
					flexDirection: 'column',
					minHeight: '100vh',
					marginLeft: NAVBAR_WIDTH,
					width: `calc(100vw - ${NAVBAR_WIDTH})`,
					maxWidth: `calc(100vw - ${NAVBAR_WIDTH})`,
					height: `calc(100vh - ${TOP_BAR_HEIGHT})`,
					overflow: 'scroll',
				}}
			>
				{Contents(children)}
				<Footer />
			</Box>
			<Navbar
				sx={{
					width: NAVBAR_WIDTH,
					// weird MUI bug: if the shadow color is at the end, the minus before the horizontal shadow offset is lost
					boxShadow: `${SHADOW_COLOR} -${SHADOW_SIZE} 0 ${SHADOW_SIZE} 0`,
				}}
			/>
			<VisualCues />
		</Box>
	);
}
