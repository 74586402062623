import { gql } from '@apollo/client';
import { INotification } from '@common/types/notification';
import client from './apollo';
import { resetToken, saveToken, getToken } from './authUtil';
import { IUser } from '@common/types/User';
import { sleep } from '@common/misc';

export const markNotificationAsRead = async (id: string) => {
	const res = await client().mutate({
		mutation: gql`
			mutation MarkNotificationAsRead($id: String!) {
				markNotificationAsRead(id: $id)
			}
		`,
		fetchPolicy: 'no-cache',
		variables: { id },
	});
};

export const markNotificationAsUnread = async (id: string) => {
	const res = await client().mutate({
		mutation: gql`
			mutation MarkNotificationAsUnread($id: String!) {
				markNotificationAsUnread(id: $id)
			}
		`,
		fetchPolicy: 'no-cache',
		variables: { id },
	});
};

export const login = async (email: string, password: string) => {
	try {
		resetToken();
		const res = await client().mutate({
			mutation: gql`
				mutation Login($email: String!, $password: String!) {
					login(email: $email, password: $password) {
						token
					}
				}
			`,
			variables: { email, password },
			fetchPolicy: 'no-cache',
		});
		saveToken(res.data.login.token);
		return res.data;
	} catch (err) {
		console.error({ login: { err } });
		throw err;
	}
};

export const logout = () => {
	resetToken();
};

export const getCurrentUser = async (): Promise<IUser | undefined> => {
	if (!getToken()) throw new Error();

	const res = await client().query({
		query: gql`
			query CurrentUser {
				currentUser {
					id
					firstName
					lastName
					email
					phone
					role
					customerId
					profileImage
				}
			}
		`,
		fetchPolicy: 'no-cache',
	});
	return res.data.currentUser;
};

export const getNotifications = async () => {
	const res = await client().query({
		query: gql`
			query GetNotifications {
				getNotifications {
					id
					text
					createdAt
					read
				}
			}
		`,
		fetchPolicy: 'no-cache',
	});

	const convertDate = (n: INotification) => (n.createdAt = new Date(n.createdAt!.toString()));
	res.data.getNotifications.forEach(convertDate);

	return res.data.getNotifications;
};

export async function changeUserPassword(changePasswordCode: string, password: string, verifyPassword: string) {
	const res = await client().mutate({
		mutation: gql`
			mutation ChangePassword($changePasswordCode: String!, $password: String!, $verifyPassword: String!) {
				changeUserPassword(
					changePasswordCode: $changePasswordCode
					password: $password
					verifyPassword: $verifyPassword
				)
			}
		`,
		variables: { changePasswordCode, password, verifyPassword },
		fetchPolicy: 'no-cache',
	});
	return res.data;
}
