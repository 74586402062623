import { Box } from '@mui/system';
import styled from '@emotion/styled';

const MidScreenLayout = styled(Box)({
	width: '100vw',
	height: '100vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export default MidScreenLayout;
