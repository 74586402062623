import type { AppProps } from 'next/app';
import { I18nextProvider } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import theme from '@/styles/theme';
import apolloClient from '@/api/server/apollo';
import { AuthenticationProtection, AuthProvider } from '@/lib/providers/AuthProvider';
import { VisualLayoutProvider } from '@/lib/providers/VisualLayoutProvider';
import { NotificationsProvider } from '@/lib/providers/NotificationsProvider';
import { DataProvider } from '@/lib/providers/DataProvider';
import RTLProvider from '@/lib/providers/RTLProvider';
import NavbarLayout from '@/components/layout/navbarLayout';
import ErrorBoundary from '@/components/layout/errorBoundary';
import Error from '@/pages/500';
import i18n from '@/localization/i18n';
import Globals from './Globals';

import '@/styles/globals.scss';

require('dayjs/locale/he');
dayjs.locale('he');
dayjs.extend(duration);

import { LicenseInfo } from '@mui/x-license-pro';
import { HOUR } from '@common/misc';
import { useEffect } from 'react';
import { RecordingProvider } from '@/lib/providers/RecordingProvider';
const licenses = require('@/../licenses.json'); // top level folder, above src
LicenseInfo.setLicenseKey(licenses.muiGrid);

if (process.version) {
	const nodeVersion = parseInt(process.version.match(/\d+/)![0]);
	if (nodeVersion < 18) {
		console.log(`\n\nTextify cannot run on node version ${nodeVersion}. Node version needs to be >= 18.\n\n`);
		process.exit(0);
	}
}

export default function App({ Component, pageProps }: AppProps) {
	const getLayout = (Component as any).getLayout || NavbarLayout;

	useEffect(() => {
		// refresh page every 12hr, in case version was upgraded
		// in useEffect to make sure this line is only executed client-side (no "window" on server side)
		setTimeout(() => window.location.reload(), 12 * HOUR);
	}, []);

	return (
		<ErrorBoundary fallback={<Error />}>
			<RTLProvider>
				<VisualLayoutProvider>
					<DataProvider>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale="he"
						>
							<I18nextProvider i18n={i18n}>
								<ThemeProvider theme={theme}>
									<ApolloProvider client={apolloClient()}>
										<AuthProvider>
											<NotificationsProvider>
												<AuthenticationProtection>
													<RecordingProvider>
														<>
															<Globals />
															{getLayout(<Component {...pageProps} />)}
														</>
													</RecordingProvider>
												</AuthenticationProtection>
											</NotificationsProvider>
										</AuthProvider>
									</ApolloProvider>
								</ThemeProvider>
							</I18nextProvider>
						</LocalizationProvider>
					</DataProvider>
				</VisualLayoutProvider>
			</RTLProvider>
		</ErrorBoundary>
	);
}
