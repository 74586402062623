import React from 'react';
import { AuthContext, AuthContextType } from '@/lib/providers/AuthProvider';
import { DataContext, DataContextType } from '@/lib/providers/DataProvider';

let _dataContext: DataContextType, _authContext: AuthContextType;

export const getDataContext = () => _dataContext;
export const getAuthContext = () => _authContext;

function ContextInitiator({
	dataContext,
	authContext,
}: {
	dataContext: DataContextType;
	authContext: AuthContextType;
}) {
	_dataContext = dataContext;
	_authContext = authContext;

	return null;
}

export default class Globals extends React.Component {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<AuthContext.Consumer>
				{(authContext) => (
					<DataContext.Consumer>
						{(dataContext) => (
							<ContextInitiator
								dataContext={dataContext}
								authContext={authContext}
							/>
						)}
					</DataContext.Consumer>
				)}
			</AuthContext.Consumer>
		);
	}
}
