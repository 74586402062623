export function stringOrMultipleRows(text: string | string[]) {
	if (typeof text === 'string') {
		if (!text.includes('\n')) return text;
		text = text.split('\n');
	}
	return text.map((row) => (
		<>
			{row}
			<br />
		</>
	));
}
