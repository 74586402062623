import { PHONE_REGEX } from '../misc';
import { object, string, date, bool, InferType, TestContext, number } from 'yup';
import { BaseSchema } from './common';

export enum UserRole {
	RECORDER = 'RECORDER',
	TRANSCRIPTIONIST = 'TRANSCRIPTIONIST',
	EDITOR = 'EDITOR',
	MANAGER = 'MANAGER',
	CUSTOMER = 'CUSTOMER',
	ADMIN = 'ADMIN',
}

export const userSchema = object({
	...BaseSchema,
	firstName: string().required().min(2).label('שם פרטי'),
	lastName: string().required().min(2).label('שם משפחה'),
	email: string().required().email().label('אימייל'),
	phone: string().required().matches(PHONE_REGEX).min(9).label('טלפון'),
	role: string().required().oneOf(Object.values(UserRole)).label('תפקיד'),
	changePasswordCode: string(),
	changePasswordExpiration: date(),
	isActive: bool().nullable().notRequired(),
	customerId: string().nullable().notRequired(),
	profileImage: string().notRequired(),
	pricePerWord: number()
		.label('מחיר למילה')
		.when('role', {
			is: UserRole.EDITOR,
			then: (schema) => schema.positive().required().max(10000),
			otherwise: (schema) => schema.strip(),
		}),
	pricePerHour: number()
		.label('מחיר לשעה')
		.when('role', {
			is: UserRole.EDITOR,
			then: (schema) => schema.positive().required().max(10000),
			otherwise: (schema) => schema.strip(),
		}),
});

export type IUser = InferType<typeof userSchema>;

export const loginSchema = object({
	email: string().required().email(),
	password: string().required().min(6),
});

const PASSWORD_MUST_INCLUDE = [
	/^\S+$/, // from start (^) to end ($), all chars must be non-space (\S)
	/[a-z]|[א-ת]/, // small letter or hebrew letter
	/[A-Z]/, // a capital letter
	/[0-9]/, // a digit
	/[!@#$%^&*()`~[\]{}|;':",.\/<>?]/, // special char
];
const validPassword = (password: string) => !PASSWORD_MUST_INCLUDE.find((regex) => !password.match(regex));
const verifyPassword = (verify: string, context: TestContext) => verify == context.parent['password'];

export const setPasswordSchema = object({
	password: string()
		.required('REQUIRED')
		.min(6, 'MIN_CHARS[6]')
		.test('invalid-password', 'הסיסמא חייבת לכלול אות, ספרה וסימן פיסוק', validPassword),
	verifyPassword: string().required().test('invalid-verify-password', 'סיסמאות לא תואמות', verifyPassword),
});

export function isUserAdmin(user?: IUser) {
	return user && user.role === UserRole.ADMIN;
}

export function getUserFullName(user?: IUser) {
	if (!user || (!user.firstName && !user.lastName)) return '';
	return (user.firstName || '') + ' ' + (user.lastName || '');
}

export function isUserManager(user?: IUser, allowAdmin = true) {
	return user && (user.role === UserRole.MANAGER || (allowAdmin && isUserAdmin(user)));
}

export function isUserEditor(user?: IUser) {
	return user && user.role === UserRole.EDITOR;
}

export function isUserTranscriptionist(user?: IUser) {
	return user && user.role === UserRole.TRANSCRIPTIONIST;
}

export function isUserTranscriptionistOrEditor(user?: IUser, orManager = true) {
	return isUserTranscriptionist(user) || isUserEditor(user) || (orManager && isUserManager(user, true));
}

export function isUserTranscriptionistOrEditorOrCustomer(user?: IUser, orManager = true) {
	return (
		isUserTranscriptionist(user) ||
		isUserEditor(user) ||
		isUserCustomer(user) ||
		(orManager && isUserManager(user, true))
	);
}

export function isUserRecorder(user?: IUser) {
	return user && user.role === UserRole.RECORDER;
}

export function isUserCustomer(user?: IUser) {
	return user && user.role === UserRole.CUSTOMER;
}

export function isUserCustomerOrManager(user?: IUser) {
	return isUserCustomer(user) || isUserManager(user);
}

export function getUserRoles(user?: IUser) {
	let { isAdmin, isManager, isManagerOrAdmin, isCustomer, isEditor } = {} as any;
	isAdmin = isUserAdmin(user);
	isManager = isUserManager(user);
	isManagerOrAdmin = isManager || isAdmin;
	isCustomer = isUserCustomer(user);
	isEditor = isUserEditor(user);
	return { isAdmin, isManager, isManagerOrAdmin, isCustomer, isEditor };
}

export function canUserAccessCustomer(user: IUser, customerId: string) {
	return isUserManager(user) || isUserTranscriptionistOrEditor(user, true) || user.customerId === customerId;
}
