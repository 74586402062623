import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { IRecording, extraData } from '@common/types/Recording';

interface RecordingContextType {
	recording?: IRecording;
	setRecording: (recording: IRecording) => void;
}

const RecordingContext = createContext({ recording: undefined, setRecording: () => {} } as RecordingContextType);

export function RecordingProvider({ children }: { children: ReactElement | ReactElement[] }) {
	const [recording, setRecording] = useState<IRecording | undefined>(undefined);

	return <RecordingContext.Provider value={{ recording, setRecording }}>{children}</RecordingContext.Provider>;
}

export function useRecordingContext() {
	return useContext(RecordingContext);
}
