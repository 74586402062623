import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationHE from '@common/localization/he/translation.json';

const resources = {
	he: {
		translation: translationHE,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'he',
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
});

export function tx(prefix: string, value?: string | string[], join?: boolean): string | string[] {
	if (value instanceof Array) {
		return value.map((v) => tx(prefix, v)) as string[];
	}
	const key = prefix + (value ? '|' + value : '');
	let res = i18n.t(key) || key;
	if (Array.isArray(res) && join) res = res.join('\n');
	return res;
}

export function txWithPrefix(valueWithPrefix: string) {
	if (typeof valueWithPrefix === 'string' && valueWithPrefix.includes('|')) {
		const prefix = valueWithPrefix.split('|')[0];
		const value = valueWithPrefix.substring(prefix.length + 1);
		return tx(prefix, value);
	}
	return tx(valueWithPrefix) || valueWithPrefix;
}

export function isTx(key: string) {
	return key && typeof key === 'string' && i18n.t(key) && i18n.t(key) !== key;
}

export default i18n;
