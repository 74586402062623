import jwt from 'jsonwebtoken';

const TOKEN_ENTRY = '__TOKEN__';

export function getToken() {
	return window.localStorage.getItem(TOKEN_ENTRY);
}

export function saveToken(token: string) {
	return window.localStorage.setItem(TOKEN_ENTRY, token);
}

export function resetToken() {
	return window.localStorage.removeItem(TOKEN_ENTRY);
}

export function getJwtHeader(): any {
	const token = getToken();
	const authorization = token ? `Bearer ${token}` : '';
	return { Authorization: authorization };
}

export function getTimeToTokenExpiration(): any {
	const token = getToken();
	if (!token) return false;
	const t = jwt.decode(token) as any;
	return new Date(t.exp * 1000).valueOf() - new Date().valueOf();
}

export function isTokenExpired(): any {
	return getTimeToTokenExpiration() <= 0;
}
