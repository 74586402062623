import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, AlertColor, Box, Fade, Link } from '@mui/material';
import theme from '@/styles/theme';
import { stringOrMultipleRows } from '@/lib/jsxUtils';
import { useVisualLayoutContext } from '@/lib/providers/VisualLayoutProvider';
import { isHebrewText } from '@common/misc';

export interface VisualCue {
	id: string;
	text: string | string[];
	details?: string | string[];
	type: AlertColor;
	context?: string;
}

function CueCard({ cue, autoHideDuration, show }: { cue: VisualCue; autoHideDuration?: number; show: boolean }) {
	const { removeCue } = useVisualLayoutContext();
	const [showDetails, setShowDetails] = useState(isHebrewText(cue.details));

	const close = useCallback(() => {
		removeCue(cue);
	}, [removeCue, cue]);

	useEffect(() => {
		if (autoHideDuration) setTimeout(close, autoHideDuration);
	}, [autoHideDuration, close]);

	let allDetails = !cue.details ? null : Array.isArray(cue.details) ? cue.details : [cue.details];

	return (
		<Fade
			in={show}
			timeout={300}
		>
			<Box sx={{ margin: '1rem', maxHeight: '10vh', overflowY: 'scroll' }}>
				<Alert
					sx={{ maxHeight: '50vh', alignItems: 'flex-start' /*  sx={{ alignItems: 'flex-start' }} */ }}
					variant="filled"
					severity={cue.type}
					onClose={close}
				>
					<Box>
						<Box>
							{stringOrMultipleRows(cue.text)}
							{cue.details && (
								<Link
									sx={{
										cursor: 'pointer',
										marginLeft: '0.5rem',
										color: theme.palette.primary.contrastText,
										textDecorationColor: theme.palette.primary.contrastText,
										opacity: 0.7,
									}}
									onClick={() => setShowDetails(!showDetails)}
								>
									פרטים
								</Link>
							)}
						</Box>
						{showDetails && allDetails && (
							<Box style={{ maxHeight: '10em', overflow: 'scroll', flexDirection: 'column' }}>
								<ErrorDetails details={allDetails} />
							</Box>
						)}
					</Box>
				</Alert>
			</Box>
		</Fade>
	);
}

export function ErrorDetails({ details }: { details: any[] }) {
	{
		return (
			<>
				{details.map((detail) => {
					if (Array.isArray(detail)) return <ErrorDetails details={detail} />;
					if (detail?.message) detail = detail.message;
					const detailText = typeof detail === 'string' ? t(detail) : JSON.stringify(detail);
					const isHebrew = isHebrewText(detailText);
					const style: CSSProperties = {
						textAlign: isHebrew ? 'right' : 'left',
						direction: isHebrew ? 'rtl' : 'ltr',
					};
					return (
						<p
							key={detail}
							style={style}
						>
							{detailText.split('\n').map((row, i) => (
								<span key={i.toString()}>
									{row}
									<br />
								</span>
							))}
						</p>
					);
				})}
			</>
		);
	}
}

export default function VisualCues() {
	const [cueCards, setCueCards] = useState<VisualCue[]>([]);
	const { cues } = useVisualLayoutContext();

	useEffect(() => {
		const newCues = cues.find((cue) => !cueCards.includes(cue));
		setTimeout(() => setCueCards(cues), newCues ? 0 : 500);
	}, [cues, cueCards]);

	return (
		// first box with right scrollbar, second box fixes direction
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				zIndex: 9999,
				maxHeight: '22rem',
				overflow: 'scroll',
				direction: 'rtl',
				paddingTop: '1rem',
				maskImage: 'linear-gradient(to top, black 0%, black 80%, transparent 100%)',
			}}
		>
			<Box sx={{ direction: 'ltr' }}>
				{cueCards.map((cue) => {
					const autoHideDuration = ['error', 'warning'].includes(cue.type) ? {} : { autoHideDuration: 6000 };
					return (
						<CueCard
							key={cue.id}
							{...autoHideDuration}
							cue={cue}
							show={cues.includes(cue)}
						/>
					);
				})}
			</Box>
		</Box>
	);
}
